// Disabling this line-based conflicts with newline rule. Pita.
/* eslint-disable react/no-danger */
import CloseIcon from "@mui/icons-material/Close";
import {
  Backdrop,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fade,
  IconButton,
  Typography,
  TypographyVariant,
} from "@mui/material";
import { ReactElement, ReactNode, useCallback, useMemo } from "react";
import {
  SettingEmoji,
  SuccessEmoji,
  WarningEmoji,
} from "../../../helpers/clientSide/emojis";
import { sanitizeHtml } from "../../../helpers/general/sanitizeHtml";
import { useId } from "../../../hooks/useId";
import { EmojiComponent } from "../../01-atoms/Emoji/createEmoji";
import { NWONDialogLevel, NWONDialogProps } from "./NWONDialogInterfaces";
import { useNWONDialogStyles } from "./NWONDialogStyles";

const iconsForLevels: Record<NWONDialogLevel, EmojiComponent | null> = {
  [NWONDialogLevel.Info]: null,
  [NWONDialogLevel.Warning]: WarningEmoji,
  [NWONDialogLevel.Success]: SuccessEmoji,
  [NWONDialogLevel.Setting]: SettingEmoji,
};

/**
 * Show a modal box with a title and contents.
 */
const NWONDialog = (props: NWONDialogProps): JSX.Element => {
  const {
    isOpen,
    onClose,
    children,
    title,
    dialogActions,
    titleVariant,
    dialogContentText,
  } = props;
  const { classes } = useNWONDialogStyles();

  if (props.level && props.emoji) {
    // We want to warn here.
    // eslint-disable-next-line no-console
    console.warn(
      `Using both "level" and "emoji" on NWONModal is redundant, since "level" will be without effect.`
    );
  }

  const LevelIcon =
    props.emoji || iconsForLevels[props.level || NWONDialogLevel.Info];

  const titleId = useId();
  const textId = useId();

  const wrapContent = useCallback(
    (
      element: string | ReactNode,
      typographyVariant?: TypographyVariant,
      postTextElement?: ReactElement
    ): ReactNode => {
      return typeof element === "string" ? (
        <Typography variant={typographyVariant ? typographyVariant : "body1"}>
          <span dangerouslySetInnerHTML={{ __html: sanitizeHtml(element) }} />

          {postTextElement}
        </Typography>
      ) : (
        element
      );
    },
    []
  );

  const wrappedDialogTitle = useMemo(
    () =>
      wrapContent(
        title,
        titleVariant || "h3",
        LevelIcon ? (
          <span className={classes.levelIcon}>
            <LevelIcon />
          </span>
        ) : undefined
      ),
    [wrapContent, title, titleVariant, LevelIcon, classes.levelIcon]
  );

  const wrappedDialogContent = useMemo(
    () => wrapContent(children),
    [children, wrapContent]
  );

  const wrappedDialogContentText = useMemo(
    () => wrapContent(dialogContentText),
    [dialogContentText, wrapContent]
  );

  const wrappedDialogActions = useMemo(
    () => wrapContent(dialogActions),
    [dialogActions, wrapContent]
  );

  return (
    <Dialog
      aria-labelledby={titleId}
      aria-describedby={textId}
      open={isOpen}
      onClose={onClose}
      closeAfterTransition={true}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        onClick: (e) => {
          e.stopPropagation();
          e.preventDefault();
          onClose();
        },
      }}
    >
      <DialogTitle className={classes.dialogTitle}>
        {wrappedDialogTitle}

        <IconButton
          arial-label="close"
          className={classes.closeIconButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <Fade in={isOpen}>
        <DialogContent className={classes.dialogContent}>
          {wrappedDialogContentText && (
            <DialogContentText>{wrappedDialogContentText}</DialogContentText>
          )}

          {wrappedDialogContent}
        </DialogContent>
      </Fade>

      {wrappedDialogActions && (
        <DialogActions>{wrappedDialogActions}</DialogActions>
      )}
    </Dialog>
  );
};

export default NWONDialog;
