import AccessTimeIcon from "@mui/icons-material/AccessTime";
import SecurityIcon from "@mui/icons-material/Security";
import { Box, ListItemIcon, Typography } from "@mui/material";
import { useTranslation } from "next-i18next";
import { ReactNode, useCallback, useMemo, useState } from "react";
import {
  SeeNoEvilEmoji,
  SuccessEmoji,
} from "../../../helpers/clientSide/emojis";
import { EmojiComponent } from "../../01-atoms/Emoji/createEmoji";
import HtmlFromLangFile from "../../01-atoms/HtmlFromLangFile/HtmlFromLangFile";
import { NWONGoogleRatings } from "../../01-atoms/NWONGoogleRatings/NWONGoogleRatings";
import { NWONLeadForm } from "../../03-organisms/NWONLeadForm/NWONLeadForm";
import { SamdockRequestState } from "../../03-organisms/NWONLeadForm/NWONLeadFormInterfaces";
import NWONDialog from "../NWONModal/NWONDialog";
import { NWONLeadDialogProps } from "./NWONLeadDialogInterfaces";
import { useNWONLeadDialogStyles } from "./NWONLeadDialogStyles";

export const NWONLeadDialog = (props: NWONLeadDialogProps): JSX.Element => {
  const { classes } = useNWONLeadDialogStyles();
  const { t } = useTranslation<"common">();

  const [requestState, setRequestState] = useState<SamdockRequestState>(
    SamdockRequestState.NotSend
  );

  const onRequestSent = useCallback(
    (state: SamdockRequestState) => setRequestState(state),
    []
  );

  const dialogContentText: Record<SamdockRequestState, ReactNode> = useMemo(
    () => ({
      [SamdockRequestState.NotSend]: (
        <Box className={classes.subHeadline}>
          <Box>
            <ListItemIcon>
              <AccessTimeIcon />

              <Typography variant="caption" className={classes.iconText}>
                {t("leads.waitDurationInformation")}
              </Typography>
            </ListItemIcon>
          </Box>

          <Box>
            <ListItemIcon>
              <SecurityIcon />

              <Typography variant="caption" className={classes.iconText}>
                {t("leads.privacyInformation")}
              </Typography>
            </ListItemIcon>
          </Box>
        </Box>
      ),
      [SamdockRequestState.Success]: (
        <Box>
          <HtmlFromLangFile
            contentContainingHtml={t("leads.requestResponse.success")}
          />
        </Box>
      ),
      [SamdockRequestState.Failure]: (
        <Box>
          {t("leads.requestResponse.failure")}

          <br />

          <br />

          {`${t("common.email")}: `}

          <a href={`tel:${t("contactInformation.email")}`}>
            {t("contactInformation.email")}
          </a>

          <br />

          {`${t("common.phoneNumber")}: `}

          <a href={`mailto:${t("contactInformation.phone")}`}>
            {t("contactInformation.phone")}
          </a>
        </Box>
      ),
    }),
    [classes.iconText, classes.subHeadline, t]
  );

  const dialogContent: Record<SamdockRequestState, ReactNode> = useMemo(
    () => ({
      [SamdockRequestState.NotSend]: (
        <>
          <NWONLeadForm onRequestSent={onRequestSent} />

          <NWONGoogleRatings wrapperClassName={classes.googleRatings} />
        </>
      ),
      [SamdockRequestState.Success]: undefined,
      [SamdockRequestState.Failure]: undefined,
    }),
    [classes.googleRatings, onRequestSent]
  );

  const emoji: Record<SamdockRequestState, EmojiComponent | undefined> =
    useMemo(
      () => ({
        [SamdockRequestState.NotSend]: undefined,
        [SamdockRequestState.Success]: SuccessEmoji,
        [SamdockRequestState.Failure]: SeeNoEvilEmoji,
      }),
      []
    );

  const title: Record<SamdockRequestState, string> = useMemo(
    () => ({
      [SamdockRequestState.NotSend]: t("leads.titleModal"),
      [SamdockRequestState.Success]: t("leads.requestResponse.successTitle"),
      [SamdockRequestState.Failure]: t("leads.requestResponse.failureTitle"),
    }),
    [t]
  );

  return (
    <NWONDialog
      title={title[requestState]}
      titleVariant="h4"
      emoji={emoji[requestState]}
      isOpen={props.dialogProps?.isOpen || false}
      onClose={
        props.dialogProps?.onClose ? props.dialogProps?.onClose : () => {}
      }
      dialogContentText={dialogContentText[requestState]}
    >
      {dialogContent[requestState]}
    </NWONDialog>
  );
};
