import { makeStyles } from "tss-react/mui";

export const useNWONLeadDialogStyles = makeStyles()((theme) => ({
  subHeadline: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    "& > div": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      marginBottom: theme.spacing(0.5),
    },
  },
  iconText: {
    marginLeft: theme.spacing(0.5),
  },
  googleRatings: {
    marginTop: theme.spacing(2),
  },
}));
