import { makeStyles } from "tss-react/mui";

export const useNWONGoogleRatingsStyles = makeStyles()((theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  title: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: theme.spacing(0.3),
  },
  googleLink: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  newTabIcon: {
    marginLeft: theme.spacing(0.3),
    fontSize: theme.spacing(0.8),
  },
  ratings: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  ratingText: {
    marginLeft: theme.spacing(0.3),
  },
}));
