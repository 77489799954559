import { UseFormReturn } from "react-hook-form";

export enum SamdockRequestState {
  NotSend = "notsend",
  Success = "success",
  Failure = "failure",
}

export interface LeadFormValues {
  name: string;
  company?: string;
  email: string;
  phoneNumber?: string;
  acceptAgb: boolean;
  information?: string;
}

export interface NWONLeadFormProps {
  onRequestSent: (status: SamdockRequestState) => void;
}

export interface NWONLeadFormViewModel {
  useFormReturn: UseFormReturn<LeadFormValues, unknown>;
  onLeadFormSubmit: (data: LeadFormValues) => void;
}

/**
 * This is only assumed as it is not part of the documentation
 */
export enum Gender {
  Male = "male",
  Female = "female",
}

/**
 * Interface for a Samdock Post Body
 * from https://de.samdock.help/article/27-api
 */
export interface SamdockPostBody {
  firstName?: string;
  lastName?: string;
  email?: string;
  birthday?: string | Date;
  gender?: Gender;
  namePrefix?: string;
  nameSuffix?: string;
  website?: string;
  street?: string;
  number?: string;
  postcode?: string;
  city?: string;
  phoneNumber?: string;
  mobileNumber?: string;
  officeNumber?: string;
  homeNumber?: string;
  privateNumber?: string;
  faxNumber?: string;
  instagram?: string;
  linkedin?: string;
  facebook?: string;
  twitter?: string;
  xing?: string;
  companyName?: string;
  companyEmail?: string;
  companyTaxID?: string;
  companyStreet?: string;
  companyNumber?: string;
  companyPostcode?: string;
  companyCity?: string;
  companyPhoneNumber?: string;
  companyFaxNumber?: string;
  companyWebsite?: string;
  companyInstagram?: string;
  companyLinkedin?: string;
  companyFacebook?: string;
  companyTwitter?: string;
  companyXing?: string;
  leadRequirements?: string;
}
