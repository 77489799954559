import { makeStyles } from "tss-react/mui";

export const useNWONLeadFormStyles = makeStyles()((theme) => ({
  wrapper: { width: "100%" },
  submitButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    "& svg": {
      marginLeft: theme.spacing(0.5),
    },
  },
  checkBoxWithError: { color: theme.palette.error.main },
}));
