import { Grid, Typography } from "@mui/material";
import { NWONPage } from "../../../../config/NWONPage";
import { usePageInformationContext } from "../../../state/pageInformationContext/PageInformationContext";
import HtmlFromLangFile from "../../01-atoms/HtmlFromLangFile/HtmlFromLangFile";
import NWONAnimatedLogoBackground from "../../01-atoms/NWONAnimatedLogoBackground/NWONAnimatedLogoBackground";
import NWONCTAButton from "../../02-molecules/NWONCTAButton/NWONCTAButton";
import { ButtonLabel } from "../../02-molecules/NWONCTAButton/NWONCTAButtonInterfaces";
import NWONHeroSectionLogos from "../../02-molecules/NWONHeroSectionLogos/NWONHeroSectionLogos";
import NWONHeroSlider from "../../02-molecules/NWONHeroSlider/NWONHeroSlider";
import NWONSection from "../NWONSection/NWONSection";
import { NWONHeroSectionProps } from "./NWONHeroSectionInterfaces";
import { useNWONHeroSectionStyles } from "./NWONHeroSectionStyles";

const NWONHeroSection = (props: NWONHeroSectionProps): JSX.Element => {
  const { classes } = useNWONHeroSectionStyles(props);

  const pageInformation = usePageInformationContext();
  const isHomePage = pageInformation.page === NWONPage.Home;
  const isProjectPage = pageInformation.page === NWONPage.Projects;

  return (
    <NWONSection
      className={classes.wrapper}
      containerProps={{
        style: { alignItems: "flex-start" },
      }}
    >
      <Grid
        className={classes.gridLeft}
        container={true}
        xs={12}
        md={6}
        item={true}
      >
        <Typography variant="body2">{props.caption}</Typography>

        <Typography variant="h1" className={classes.title}>
          <HtmlFromLangFile contentContainingHtml={props.title} />
        </Typography>

        <Typography variant="body1">
          {typeof props.subtitle === "string" ? (
            <HtmlFromLangFile contentContainingHtml={props.subtitle} />
          ) : (
            props.subtitle
          )}
        </Typography>

        {props.ctaButtonDisabled !== true && (
          <NWONCTAButton
            buttonLabel={
              isHomePage || isProjectPage
                ? ButtonLabel.Contact
                : ButtonLabel.Offer
            }
          />
        )}

        {props.logos ? <NWONHeroSectionLogos logos={props.logos} /> : undefined}
      </Grid>

      {props.slideInformation && (
        <NWONHeroSlider
          interval={6000}
          slideInformation={props.slideInformation}
        />
      )}

      <NWONAnimatedLogoBackground />
    </NWONSection>
  );
};

export default NWONHeroSection;
