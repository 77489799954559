import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Box, Rating, Typography } from "@mui/material";
import { useTranslation } from "next-i18next";
import Link from "../Link";
import { NWONGoogleRatingsProps } from "./NWONGoogleRatingsInterfaces";
import { useNWONGoogleRatingsStyles } from "./NWONGoogleRatingsStyles";

export const NWONGoogleRatings = (
  props: NWONGoogleRatingsProps
): JSX.Element => {
  const { classes, cx } = useNWONGoogleRatingsStyles();
  const { t } = useTranslation<"common">();

  return (
    <Box className={cx(classes.wrapper, props.wrapperClassName)}>
      <Box className={classes.title}>
        <Typography variant="caption">{t("google.rating.label")}</Typography>

        <Link
          className={classes.googleLink}
          target="_blank"
          href={t("google.shareLink")}
        >
          <OpenInNewIcon className={classes.newTabIcon} />
        </Link>
      </Box>

      <Box className={classes.ratings}>
        <Rating
          name="NWON Google Ratings"
          value={4.6}
          precision={0.1}
          readOnly={true}
        />

        <Typography className={classes.ratingText} variant="caption">
          4.6/5
        </Typography>
      </Box>
    </Box>
  );
};
