import { important } from "@nvon/react-toolbox";
import { makeStyles } from "tss-react/mui";
import { NWONHeroSectionProps } from "./NWONHeroSectionInterfaces";

export const useNWONHeroSectionStyles = makeStyles<NWONHeroSectionProps>()(
  (theme, props) => ({
    wrapper: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white,
      overflow: "hidden",
      position: "relative",
      paddingTop: theme.spacing(10),
      paddingBottom: theme.spacing(10),
      [theme.breakpoints.only("sm")]: {
        paddingBottom: props.slideInformation
          ? theme.spacing(15)
          : theme.spacing(8),
      },

      [theme.breakpoints.only("xs")]: {
        paddingTop: theme.spacing(5),
        paddingBottom: props.slideInformation
          ? theme.spacing(12)
          : theme.spacing(5),
        minHeight: props.slideInformation ? "70vh" : "auto",
      },
      // To avoid overlapping of the image and the text on certain screen sizes
      ["@media (min-width:450px) and (max-width:600px)"]: {
        paddingTop: theme.spacing(8),
        paddingBottom: props.slideInformation
          ? theme.spacing(18)
          : theme.spacing(5),
      },
    },
    gridLeft: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "center",
      zIndex: 1,
      "& > p": {
        maxWidth: "80%",
        [theme.breakpoints.down("lg")]: {
          maxWidth: "unset",
        },
        [theme.breakpoints.only("sm")]: {
          maxWidth: "75%",
        },
      },
      "& > *": {
        marginBottom: theme.spacing(1.5),
      },
    },
    title: {
      // To avoid the overflow of the title on the "sub-service" page
      [theme.breakpoints.only("xs")]: {
        fontSize: important(theme.spacing(1.75)),
      },
    },
  })
);
