import { Button } from "@mui/material";
import { rethrow } from "@nvon/baseline";
import { useBooleanState, useSmoothWindowScroll } from "@nvon/react-toolbox";
import router from "next/router";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
  CtaStyleOption,
  UrlParameter,
} from "../../../../config/GoogleAdConfiguration";
import { headerHeight } from "../../../helpers/clientSide/constants";

import { useGetValueUrlParameter } from "../../../hooks/urls/useGetValueUrlParameter";
import { useHasUrlParameter } from "../../../hooks/urls/useHasUrlParameter";
import { useLinkForNavigationItem } from "../../../hooks/useLinkForNavigationItem";
import { NWONLeadDialog } from "../NWONLeadDialog/NWONLeadDialog";
import { ButtonLabel, NWONCTAButtonProps } from "./NWONCTAButtonInterfaces";

const NWONCTAButton = (props: NWONCTAButtonProps): JSX.Element => {
  const { t: tCommon } = useTranslation("common");
  const buttonLabelOffer = tCommon("heroSection.ctaButton.offer");
  const buttonLabelContact = tCommon("heroSection.ctaButton.contact");

  const { t } = useTranslation();
  const navigationItem = {
    anchor: t("menuItems.contact").toLowerCase(),
    title: t("menuItems.contact"),
  };

  const hasCtaStyleUrlParameter = useHasUrlParameter(UrlParameter.CtaStyle);
  const hrefToContactSection = useLinkForNavigationItem(
    navigationItem,
    hasCtaStyleUrlParameter ? UrlParameter.CtaStyle : undefined
  );

  const ctaStyleValue = useGetValueUrlParameter(UrlParameter.CtaStyle);
  const isCtaStyleContactInformation =
    ctaStyleValue === CtaStyleOption.ContactInformation;
  const isCtaStyleForm = ctaStyleValue === CtaStyleOption.Form;

  const {
    state: isFormModalOpen,
    on: setIsFormModalOpen,
    off: setIsFormModalClose,
  } = useBooleanState(false);

  const smoothScrollFn = useSmoothWindowScroll({
    animationOptions: {
      duration: 1.5,
    },
    defaultOffset: 2 * headerHeight,
  });

  const id = hrefToContactSection.toString().split("#")[1];

  const scrollOnLinkWithHashClick = useCallback(() => {
    const targetElement = document.getElementById(id);
    if (targetElement) {
      smoothScrollFn(targetElement);
      router.push(`#${id}`, undefined, { shallow: true }).catch(rethrow);
    }
  }, [id, smoothScrollFn]);

  return (
    <>
      <Button
        variant={props.buttonVariant || "contained"}
        href={isCtaStyleContactInformation ? hrefToContactSection : undefined}
        onClick={
          isCtaStyleForm ? setIsFormModalOpen : scrollOnLinkWithHashClick
        }
        className={props.buttonClass}
      >
        {props.buttonLabel === ButtonLabel.Contact
          ? buttonLabelContact
          : buttonLabelOffer}
      </Button>

      <NWONLeadDialog
        dialogProps={{
          isOpen: isFormModalOpen,
          onClose: setIsFormModalClose,
        }}
      />
    </>
  );
};

export default NWONCTAButton;
