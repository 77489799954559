import { useRouter } from "next/router";
import { useCallback } from "react";
import { useForm } from "react-hook-form";
import {
  NWONTrackingEvent,
  trackEvent,
} from "../../00-globals/TruendoGoogleTagManager/trackEvent";
import {
  LeadFormValues,
  NWONLeadFormProps,
  NWONLeadFormViewModel,
  SamdockPostBody,
  SamdockRequestState,
} from "./NWONLeadFormInterfaces";

const SAMDOCK_API_URL =
  "https://samdock.app/api/contacts/drafts/9wyePw5sXgbTlr_lUJJ8s";

export const useNWONLeadFormViewModel = ({
  onRequestSent,
}: NWONLeadFormProps): NWONLeadFormViewModel => {
  const useFormReturn = useForm<LeadFormValues>();
  const router = useRouter();

  const onLeadFormSubmit = useCallback(
    (data: LeadFormValues) => {
      const dataToSend: SamdockPostBody = {
        firstName: data.name,
        email: data.email,
        phoneNumber: data.phoneNumber,
        companyName: data.company,
        leadRequirements: data.information,
        website: router.asPath,
      };

      fetch(SAMDOCK_API_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataToSend),
      }).then(
        () => onRequestSent(SamdockRequestState.Success),
        () => onRequestSent(SamdockRequestState.Failure)
      );
      trackEvent(NWONTrackingEvent.SamDockLeadFormSendButtonClicked);
    },
    [onRequestSent, router.asPath]
  );

  return { useFormReturn, onLeadFormSubmit };
};
