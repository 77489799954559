import { makeStyles } from "tss-react/mui";

export const useNWONHeroSectionLogosStyles = makeStyles()((theme) => ({
  wrapper: {
    marginTop: theme.spacing(3),
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    flexWrap: "wrap",
    [theme.breakpoints.down("lg")]: {
      marginTop: theme.spacing(2),
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(2),
    },
  },
  caption: {
    color: theme.palette.primary.light,
    marginBottom: theme.spacing(1),
  },
  logosWrapper: {
    rowGap: theme.spacing(1),
    columnGap: theme.spacing(3),
    [theme.breakpoints.down("md")]: {
      columnGap: theme.spacing(2),
    },
  },
  singleLogo: {
    filter: "grayscale(0.9)",
    opacity: 0.8,
    maxHeight: theme.spacing(3),
    "& img": { maxHeight: theme.spacing(3) },
  },
}));
