import { ButtonProps } from "@mui/material";

export enum ButtonLabel {
  Contact = "contact",
  Offer = "offer",
}

export interface NWONCTAButtonProps {
  buttonVariant?: ButtonProps["variant"];
  buttonClass?: string;
  buttonLabel: ButtonLabel;
}
