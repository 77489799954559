import { Grid, Typography } from "@mui/material";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import { isValidElement } from "react";
import { NWONHeroSectionLogosProps } from "./NWONHeroSectionLogosInterfaces";
import { useNWONHeroSectionLogosStyles } from "./NWONHeroSectionLogosStyles";

const NWONHeroSectionLogos = (
  props: NWONHeroSectionLogosProps
): JSX.Element => {
  const { classes } = useNWONHeroSectionLogosStyles();
  const { t } = useTranslation<"common">();

  return (
    <Grid container={true} className={classes.wrapper}>
      <Typography className={classes.caption} variant="caption">
        {t("heroSection.logoTitle")}
      </Typography>

      <Grid container={true} className={classes.logosWrapper}>
        {props.logos.map((logo, index) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <Grid item={true} key={index} className={classes.singleLogo}>
              {isValidElement(logo) ? (
                logo
              ) : (
                <Image placeholder="blur" src={logo} />
              )}
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};

export default NWONHeroSectionLogos;
