import { useRouter } from "next/router";
import { UrlParameter } from "../../../config/GoogleAdConfiguration";

/**
 * Get value of a specific url parameter by the key
 */
export const useGetValueUrlParameter = (
  parameterKey: UrlParameter
): string | undefined => {
  const router = useRouter();
  let parameterValue = "";

  Object.entries(router.query).forEach(([key, value]) => {
    if (typeof value === "string" && key === parameterKey) {
      parameterValue += value;
    }
  });

  return parameterValue !== "" ? parameterValue : undefined;
};
