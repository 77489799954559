import SendIcon from "@mui/icons-material/Send";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
  Typography,
} from "@mui/material";
import { rethrow } from "@nvon/baseline";
import { validate } from "email-validator";
import { useTranslation } from "next-i18next";
import { useCallback } from "react";
import { Controller } from "react-hook-form";
import HtmlFromLangFile from "../../01-atoms/HtmlFromLangFile/HtmlFromLangFile";
import { NWONLeadFormProps } from "./NWONLeadFormInterfaces";
import { useNWONLeadFormStyles } from "./NWONLeadFormStyles";
import { useNWONLeadFormViewModel } from "./NWONLeadFormViewModel";

export const NWONLeadForm = (props: NWONLeadFormProps): JSX.Element => {
  const { useFormReturn, onLeadFormSubmit } = useNWONLeadFormViewModel(props);
  const { classes } = useNWONLeadFormStyles();
  const { t } = useTranslation<"common">();

  const { handleSubmit, control, formState } = useFormReturn;

  const onFormSubmit = useCallback(() => {
    handleSubmit(onLeadFormSubmit)().catch(rethrow);
  }, [handleSubmit, onLeadFormSubmit]);

  return (
    <FormGroup className={classes.wrapper}>
      <Controller
        name={"name"}
        control={control}
        rules={{ required: true }}
        render={({ field: { onChange, value }, fieldState }) => (
          <TextField
            onChange={onChange}
            required={true}
            value={value}
            label={t("leads.nameInputLabel")}
            error={!!fieldState.error}
            fullWidth={true}
          />
        )}
      />

      <Controller
        name={"email"}
        control={control}
        rules={{
          required: true,
          validate: (value) => validate(value),
        }}
        render={({ field: { onChange, value }, fieldState }) => (
          <TextField
            onChange={onChange}
            value={value}
            required={true}
            label={t("leads.mailInputLabel")}
            placeholder="john@doe.de"
            fullWidth={true}
            type="email"
            error={!!fieldState.error}
            margin="normal"
          />
        )}
      />

      <Controller
        name={"company"}
        control={control}
        render={({ field: { onChange, value }, fieldState }) => (
          <TextField
            onChange={onChange}
            value={value}
            placeholder="Example GmbH"
            label={t("leads.companyInputLabel")}
            error={!!fieldState.error}
            fullWidth={true}
            margin="normal"
          />
        )}
      />

      <Controller
        name={"phoneNumber"}
        control={control}
        render={({ field: { onChange, value }, fieldState }) => (
          <TextField
            onChange={onChange}
            value={value}
            label={t("leads.phoneInputLabel")}
            placeholder="+49 176 123456789"
            fullWidth={true}
            type="tel"
            error={!!fieldState.error}
            margin="normal"
          />
        )}
      />

      <Controller
        name={"information"}
        control={control}
        render={({ field: { onChange, value }, fieldState }) => (
          <TextField
            onChange={onChange}
            value={value}
            label={t("leads.informationInputLabel")}
            placeholder={t("leads.informationInputPlaceholder")}
            fullWidth={true}
            rows={2}
            multiline={true}
            error={!!fieldState.error}
            margin="normal"
          />
        )}
      />

      <FormControlLabel
        control={
          <Controller
            name={"acceptAgb"}
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Checkbox
                checked={value}
                onChange={onChange}
                required={true}
                classes={{
                  root: formState.errors.acceptAgb
                    ? classes.checkBoxWithError
                    : undefined,
                }}
              />
            )}
          />
        }
        label={
          <Typography variant="caption">
            <HtmlFromLangFile
              contentContainingHtml={t("leads.privacyCheckboxLabel")}
            />
          </Typography>
        }
        classes={{
          root: formState.errors.acceptAgb
            ? classes.checkBoxWithError
            : undefined,
        }}
      />

      <Button
        className={classes.submitButton}
        variant="contained"
        color="primary"
        onClick={onFormSubmit}
      >
        {t("leads.CTAButtonLabel")}

        <SendIcon />
      </Button>
    </FormGroup>
  );
};
