import { makeStyles } from "tss-react/mui";

export const useNWONHeroSliderStyles = makeStyles()((theme) => ({
  imageMotionDivWrapper: {
    position: "absolute",
    bottom: "-3%",
    right: "2%",
    width: "45%",
    height: "auto",
    maxHeight: "80%",
    [theme.breakpoints.down("lg")]: {
      marginTop: theme.spacing(4),
      maxHeight: "100%",
      width: "50%",
      right: "-5%",
    },
    [theme.breakpoints.only("xs")]: {
      maxHeight: "100%",
      width: "75%",
      right: 0,
    },
  },
  imageWrapper: {
    boxShadow: theme.shadows[20],
  },
  sliderImageWithLink: {
    cursor: "pointer",
  },
  leadTooltipWrapper: {
    cursor: "pointer",
  },
  leadTooltip: {
    backgroundColor: theme.palette.secondary.dark,
  },
  arrowTooltip: {
    "&:before": {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
}));
