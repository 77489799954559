import { makeStyles } from "tss-react/mui";

export const useNWONDialogStyles = makeStyles()((theme) => ({
  dialogContent: {
    backgroundColor: theme.palette.background.default,
  },
  dialogTitle: {
    backgroundColor: theme.palette.background.default,
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  closeIconButton: {
    marginLeft: theme.spacing(2),
    height: "auto",
  },
  levelIcon: {
    "& .emoji": {
      fontSize: "2rem",
    },
    marginLeft: theme.spacing(1),
    lineHeight: theme.spacing(2),
  },
  titleSpan: {
    lineHeight: theme.spacing(2),
  },
}));
