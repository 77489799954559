import { motion, Transition } from "framer-motion";
import NWONLogo from "../../../../public/media/svg/nwon_logo_dark.svg";
import { useNWONAnimatedLogoBackgroundStyles } from "./NWONAnimatedLogoBackgroundStyles";

const logoTransition: Transition = {
  repeat: 999,
  repeatType: "reverse",
  duration: 60,
  repeatDelay: 2,
  ease: "easeInOut",
};

const NWONAnimatedLogoBackground = (): JSX.Element => {
  const { classes } = useNWONAnimatedLogoBackgroundStyles();

  return (
    <motion.div
      transition={logoTransition}
      initial={{ x: -2000, scale: 12, opacity: 0.15 }}
      animate={{ x: 2000 }}
      className={classes.nwonLogoBackgroundShape}
    >
      <NWONLogo className={classes.logoSvg} />
    </motion.div>
  );
};

export default NWONAnimatedLogoBackground;
