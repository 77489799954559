import { makeStyles } from "tss-react/mui";

export const useNWONAnimatedLogoBackgroundStyles = makeStyles()(() => ({
  nwonLogoBackgroundShape: {
    position: "absolute",
    top: "10%",
    left: "10%",
    overflow: "visible",
    pointerEvents: "none",
    width: "30vw",
  },
  logoSvg: {},
}));
