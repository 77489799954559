import { TypographyVariant } from "@mui/material";
import { ReactNode } from "react";
import { EmojiComponent } from "../../01-atoms/Emoji/createEmoji";

export enum NWONDialogLevel {
  Info = "Info",
  Warning = "Warning",
  Success = "Success",
  Setting = "Setting",
}

export interface NWONDialogProps {
  isOpen: boolean;
  onClose: VoidFunction;

  /**
   * MUI Dialog options
   * When a simple string is provided, it gets wrapped in a Typography element.
   * */
  title: string | ReactNode;
  children?: string | ReactNode;
  dialogContentText?: string | ReactNode;
  dialogActions?: string | ReactNode;

  /* Additional styling */
  titleVariant?: TypographyVariant;

  /**
   * Defaults to "info".
   */
  level?: NWONDialogLevel;
  /**
   * If an emoji is provided, the level is without effect.
   */
  emoji?: EmojiComponent;
}
