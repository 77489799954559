/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Box, Stack, Typography } from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import Image from "next/image";
import Link from "next/link";
import { wrap } from "popmotion";
import { useEffect, useState } from "react";
import { UrlParameter } from "../../../../config/GoogleAdConfiguration";
import {
  mobileDevices,
  upToMediumDevices,
} from "../../../helpers/clientSide/constants";
import { useHasUrlParameter } from "../../../hooks/urls/useHasUrlParameter";
import { NWONHeroSliderProps } from "./NWONHeroSliderInterfaces";
import { useNWONHeroSliderStyles } from "./NWONHeroSliderStyles";

const singleImage = {
  zIndex: 1,
  x: 0,
  y: 0,
  opacity: 1,
  rotate: 5,
};

const variants = {
  enter: (direction: number) => {
    return {
      x: direction > 0 ? 200 : -200,
      y: direction > 0 ? 200 : -200,
      opacity: 0.5,
      rotate: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    y: 0,
    opacity: 1,
    rotate: 5,
  },
  exit: (direction: number) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 200 : -100,
      y: direction < 0 ? 200 : -100,
      opacity: 0,
      rotate: 20,
    };
  },
};

const sliderTransition = {
  x: { type: "spring", stiffness: 120, damping: 13 },
  y: { type: "spring", stiffness: 120, damping: 13 },
  opacity: { duration: 0.15 },
};

const NWONHeroSlider = (props: NWONHeroSliderProps): JSX.Element => {
  const { classes } = useNWONHeroSliderStyles();

  const [[element, direction], setPage] = useState([0, 0]);
  const imageIndex = wrap(0, props.slideInformation.length, element);

  const hasLeadUrlParameter = useHasUrlParameter(UrlParameter.Lead);

  useEffect(() => {
    const paginate = (newDirection: number) => {
      setPage([element + newDirection, newDirection]);
    };
    const timer = setInterval(() => {
      paginate(1);
    }, props.interval);
    return () => clearInterval(timer);
  }, [element, props.interval]);

  const link = props.slideInformation[imageIndex].link;
  const title = props.slideInformation[imageIndex].title;
  const imageDate = props.slideInformation[imageIndex].image;
  const hasLink = link && !hasLeadUrlParameter;

  const image = (
    <motion.div
      whileHover={{
        scale: 1.05,
      }}
    >
      <Image
        priority={true}
        src={imageDate}
        alt="project image"
        placeholder="blur"
        layout="responsive"
        sizes={`${mobileDevices} 75vw, ${upToMediumDevices} 50vw, 45vw`}
        className={hasLink ? classes.sliderImageWithLink : undefined}
      />
    </motion.div>
  );

  const imageWithLink =
    hasLink && link ? (
      <Link href={link} passHref={true}>
        {image}
      </Link>
    ) : (
      image
    );

  return props.slideInformation.length > 1 ? (
    <AnimatePresence initial={false} custom={direction}>
      <motion.div
        className={classes.imageMotionDivWrapper}
        key={element}
        custom={direction}
        variants={variants}
        initial="enter"
        animate="center"
        exit="exit"
        transition={sliderTransition}
      >
        <Stack spacing={1}>
          <Typography variant="body1">{title}</Typography>

          <Box className={classes.imageWrapper}>{imageWithLink}</Box>
        </Stack>
      </motion.div>
    </AnimatePresence>
  ) : (
    <motion.div className={classes.imageMotionDivWrapper} initial={singleImage}>
      <Image
        priority={true}
        src={props.slideInformation[0].image}
        alt="project image"
        placeholder="blur"
        layout="responsive"
        sizes={`${mobileDevices} 75vw, ${upToMediumDevices} 50vw, 45vw`}
      />
    </motion.div>
  );
};

export default NWONHeroSlider;
